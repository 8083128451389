<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <!-- <div style="width: 57%">
          <el-input
            class="hunt"
            v-model="input"
            placeholder="工会名称"
            size="large"
          />
          <el-button style="margin-left: 10px" type="primary" size="large"
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div> -->
        <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        height="calc(100vh - 220px)"
        :tree-props="{ children: 'unionDtoList' }"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
        <el-table-column label="组织名称" prop="unionName" :show-overflow-tooltip='true'/>
        <el-table-column
          label="类型"
          prop="unionType"
          align="center"
          :formatter="formatSex"
        >
           <template #default="scope">
             <el-tag size="large" :type="scope.row.unionType === 1
             ? ''
             : scope.row.unionType === 2
             ? 'warning'
             : ''
             ">
             {{ scope.row.unionType === 1 ?'工会':'部门' }}
             </el-tag>
           </template>
        </el-table-column>
        <el-table-column
          label="组织编码"
          prop="unionCode"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="240">
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.row.unionId)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
            <el-button size="default" @click="expurgate(scope.row.unionId)" class="red"
              ><el-icon class="cancel"> <Delete /> </el-icon>删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="组织删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除此组织吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large"  type="primary" @click="affirm">确定</el-button>
          <el-button class="dsa" size="large"  @click="remove=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑工会按钮 -->
    <el-dialog v-model="redact" title="工会编辑" width="30%" center>
      <div class="labors">
        <div class="labor">
          <div class="designation">编辑工会名称 ：</div>
          <div><el-input v-model="prices" placeholder="请输入工会名称" /></div>
        </div>
        <div class="labor">
          <div class="designation">是否开启权限 ：</div>
          <div>
            <el-select
              v-model="tacitly"
              class="m-2"
              placeholder="是否开启权限"
              size="default"
              :popper-append-to-body="false"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="redact = false">取消</el-button>
          <el-button type="primary" @click="precise">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Delete, EditPen,Plus} from "@element-plus/icons-vue";
import { useRouter,useRoute } from "vue-router";
import { onMounted,ref,onActivated } from 'vue';
import { tissue,Deletezz } from '../utils/api'
import { ElMessage } from "element-plus";
import qs from 'qs'
export default {
  name:"branch",
  setup() {
    // 是否刷新数据
    let translate=ref(2)
    const route=useRoute()
    onActivated(()=>{
      translate.value=route.params.userId
      if(translate.value==1){
        tiss()
      }
      console.log(translate.value)
    })




    
    // 删除按钮
    const affirm=()=>{
      let data ={
        unionId:ids.value
      }
      Deletezz(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          ElMessage({
            message: "删除成功",
            type: "success",
            center: true,
          });
          remove.value=false
          tiss()
        }else{
          ElMessage({
            message: res.data.msg,
            type: "warning",
            center: true,
          });
          remove.value=false
          tiss()
        }
      })
    }
    let ids=ref('')
    const expurgate=(id)=>{
      console.log(id)
      ids.value=id
      remove.value=true
    }
    let remove = ref(false);
    let quantum = (row, column, cellValue) => {
      return cellValue.slice(0, 11);
    };
    // 组织管理列表内容
    let texts=ref('加载中')
    // 组织管理接口
    const tiss=()=>{
      tissue().then((res)=>{
        console.log(res)
        translate.value=2
        filterTableData.value=res.data.data.unionList
        if(res.data.data.unionList.length==0){
          texts.value='暂无数据'
        }
      })
    }
    onMounted(()=>{
      tiss()
    })
    // 编辑跳转
    const copyreader = (s)=>{
      // console.log(s)
      sessionStorage.setItem('userId', s)
      router.push({name:'zzedit',params:{UserId: 1}})
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/texture')
    }
    // 判断组织类型
    let formatSex = (row, column, cellValue) => {
      if (cellValue == 1) {
        return "工会";
      } else if (cellValue === 2) {
        return "部门";
      }
    };
    // 列表数据循环 
    const filterTableData = ref([])
    return {
      affirm,
      expurgate,
      remove,
      texts,
      quantum,
      // 编辑跳
      copyreader,
      // 头部组织添加跳转
      append,
      // 判断组织类型
      formatSex,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Delete,
    EditPen,
    Plus
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.chart-card{
  height: calc(100vh - 145px);
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 36.5%;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
